import React, { Component } from "react";
import { isElectron } from 'react-device-detect'
import { connect } from "react-redux";
import { Animated } from "react-animated-css";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faForward } from "@fortawesome/free-solid-svg-icons";
import PrologContainer from "./PrologContainer";
import EpilogContainer from "./EpilogContainer";
import Question from "../../screens/questions/flow/Question";
import store from "../../../localStorage";
import preshiftManage from "../../../reducers/preshift-manager";
import { addBreamb, goBackStep } from "../../../reducers/breams-manage";
import HighQuestAnswer from "../../screens/questions/HighQuestAnswer";

class HighQuestContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_exit_timer: true,
      activeStep: 0,
      currentQuestion: 0,
      listStep: [],
      child_questions: props.question.child_questions,
      current_question: props.question.child_questions[0].question,
      current_answers: props.question.child_questions[0].answers,
      event_dt: new Date(),
      child_questions_count: props.question.child_questions.length,
      child_questions_ids: [],
      error_count: 0
    };
    store.dispatch({ type: "PAGE_LOADED", loadId: "item" });
    goBackStep();
    addBreamb({ title: this.props.t("Сhain of questions") });
    addBreamb({ title: this.props.t("Prolog") });
  }

  skipListener = (event) => {
    if (isElectron && event.ctrlKey && event.key.toLowerCase() === 'n') {
      this.theEnd();
    }
  };

  componentDidMount() {
    document.addEventListener('keydown', this.skipListener)
    this.setState({listStep: this.prepareSteps()},
      () => this.setState({ content: this.state.listStep[this.state.activeStep] }));
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.skipListener);
  };

  prepareSteps = () => {
    let listStep = [];
    listStep.push(
      <PrologContainer
        can_skip={this.props.listEducation[this.props.activeEducation].listStep[this.props.activeStep].can_skip}
        handleNextStep={this.props.handleNextStep}
        question={this.props.question}
        renderAdditionalButton={this.props.renderAdditionalButton}
        toQuestions={this.toQuestions} />
    );
    this.props.question.child_questions.forEach((question) => {
      listStep.push(
        <Question
          question={question.question}
          answers={question.answers}
          onSelectAnswer={this.onSelectAnswer}
          load={this.props.load}
          t={this.props.t}
          app={this.props.app}
          is_exit_timer={this.state.is_exit_timer}
          handleExitTimer={this.handleExitTimer}
          handleTimeExpired={this.handleTimeExpired}
          renderAdditionalButton={this.props.renderAdditionalButton} />
      );
    });
    listStep.push(
      <EpilogContainer
        question={this.props.question}
        renderAdditionalButton={this.props.renderAdditionalButton}
        theEnd={this.theEnd}
        {...this.props} />
    );
    return listStep;
  };

  toQuestions = () => {
    if (this.state.activeStep < this.state.listStep.length - 1) {
      let activeStep = 1;
      goBackStep();
      addBreamb({ title: this.props.t("Question") + activeStep + this.props.t("of") + this.state.child_questions_count });
      this.setState({
          isVisible: false,
          event_dt: new Date()},
        () => {
          setTimeout(() => {
            this.setState({
              activeStep: activeStep,
              isVisible: true});
            this.setState({ content: this.state.listStep[activeStep] });
          }, 500);
        });
      this.props.renderAdditionalButton(null);
    }
  };

  handleTimeExpired = () => this.props.history.push('/');

  handleExitTimer = () => this.setState({is_exit_timer: false});

  onSelectAnswer = (answer_id) => {
    let statePreshift = this.props.preshift,
      question_uuid = this.props.question.id,
      selected_answer = {},
      current_answers = statePreshift.question.child_questions[this.state.currentQuestion].answers,
      is_right = false,
      content = null,
      error_answer = 0;

    current_answers.forEach(answer => {
      if (answer.id === answer_id) {
        is_right = answer.is_right;
        selected_answer = answer;
        error_answer = answer.is_right ? 0 : 1;
      }
    });

    if (this.state.activeStep === this.state.listStep.length - 2) {
      let datumUhrzeit = new Date(),
        event_end_dt = "@" + Math.round(datumUhrzeit.getTime() / 1000),
        stepUuid = this.props.stepData.uuid,
        stepName = this.props.stepData.name,
        start_time = '@' + Math.round(this.state.event_dt.getTime() / 1000),
        data = {
          uuid: stepUuid,
          name: stepName,
          question_uuid: question_uuid,
          answer_id: [...this.state.child_questions_ids, answer_id],
          start_date_time: start_time,
          elapsed_time: event_end_dt,
          question_text: this.props.stepData.data.question.text,
          error_count: this.state.error_count + error_answer
        },
        flow_history = statePreshift.flow.history.steps;
        flow_history.push(data);

      preshiftManage.saveFlow({ history: {...statePreshift.flow.history} });
    };

    content = <HighQuestAnswer toNextChildQuest={this.toNextChildQuest} renderAdditionalButton={this.props.renderAdditionalButton} file={selected_answer.file} />;

    addBreamb({ title: is_right ? this.props.t("Answered correctly") : this.props.t("Answered incorrectly") });

    this.props.renderAdditionalButton(
      <div className="d-flex w-100 justify-content-end">
        <Button className="MuiButton Btn Btn_success"
          onClick={() => {
            this.toNextChildQuest();
            this.props.renderAdditionalButton(null);}} >
          <FontAwesomeIcon className="mr-4" icon={faForward} />
          {this.state.activeStep < this.state.listStep.length - 2 ? this.props.t("Next") : this.props.t("Proceed")}
        </Button>
      </div>);

    this.setState({
      is_right: is_right,
      select_answer: selected_answer,
      child_questions_ids: [...this.state.child_questions_ids, answer_id],
      error_count: this.state.error_count + error_answer },
      () => this.setState({ content: content }));
  };

  toNextChildQuest = () => {
    if (this.state.activeStep < this.state.listStep.length - 1) {
      let activeStep = this.state.activeStep + 1,
        question = this.state.child_questions[this.state.currentQuestion].question,
        answers = this.state.child_questions[this.state.currentQuestion].answers,
        currentQuestion = this.state.currentQuestion + 1;
      goBackStep();
      if (activeStep === this.state.listStep.length - 1) {
        goBackStep();
        addBreamb({ title: this.props.t("Epilog") });
      } else {
        goBackStep();
        addBreamb({ title: this.props.t("Question") + activeStep + this.props.t("of") + this.state.child_questions_count });
      }

      this.setState({
          isVisible: false,
          activeStep: activeStep,
          current_question: question,
          current_answers: answers,
          currentQuestion: currentQuestion,
          event_dt: new Date() },
        () => setTimeout(() => {
                this.setState({ isVisible: true });
                this.setState({ content: this.state.listStep[activeStep] });
              }, 500));
    } else {
      this.theEnd();
    }

    return false;
  };

  theEnd = () => {
    this.props.renderAdditionalButton(null);
    this.setState({ isVisible: false },
      () => setTimeout(this.props.handleNextStep, 500));
  };

  render() {
    let Content = this.state.content;
    return <Animated
              className="d-flex flex-column h-100 w-100"
              animationInDuration={500}
              animationOutDuration={500}
              animationIn="slideInRight"
              animationOut="slideOutLeft"
              isVisible={this.state.isVisible} >
            {Content}
          </Animated>
  };
}

const mapStateToProps = (store) => {
  return {
    load: store.loadState,
    preshift: store.preshiftState,
  };
};

export default connect(mapStateToProps)(withRouter(withTranslation()(HighQuestContainer)));