import React, {Component} from "react";
import { isElectron } from 'react-device-detect'
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faForward} from "@fortawesome/free-solid-svg-icons";
import {withTranslation} from "react-i18next";
import preshiftManage from "../../../../reducers/preshift-manager";
import DocumentAndVideo from "../../../screens/questions/flow/DocumentAndVideo";
import ExitTimer from "../../../blocks/ExitTimer";
import { setLog } from "../../../../helpers/functions";

class DocumentAndVideoContainer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            event_dt: new Date(),
            isWatched: false,
            target: null,
            is_exit_timer: true
        };
        this.timeoutID = () => {};
    };

    skipListener = (event) => {
        if (isElectron && event.ctrlKey && event.key.toLowerCase() === 'n') {
            this.handleSkip()
        }
    };

    componentDidMount() {
        document.addEventListener('keydown', this.skipListener)
        if (this.props.stepData?.can_skip) {
            this.props.renderAdditionalButton(
                <Button className="MuiButton Btn Btn_success" onClick={this.handleSkip}>
                    <FontAwesomeIcon className="mr-4" icon={faForward}/>
                    {this.props.t('Skip')}
                </Button>);
        } else {
            this.props.renderAdditionalButton(null);
        };
        let target = document.getElementById('FileViewer');
        if (target) {
            this.props.stepData?.name === "video"
                ? target.addEventListener('ended', this.handleWatched, true)
                : target.parentElement.parentElement.addEventListener('scroll', this.handleScrollWatched);
            this.setState({target: target});
        }
    };

    componentWillUnmount = () => {
        clearTimeout(this.timeoutID);
        document.removeEventListener('keydown', this.skipListener);
    }

    handleExitTimer = () => this.setState({is_exit_timer: false});

    handleWatched = () => {
        if (this.state.target) {
            if (this.props.stepData?.name === "video") {
                this.state.target.removeEventListener('ended', this.handleWatched, true);
                this.setState({isWatched: true});
                this.renderButton();
            } else {
                this.renderButton();
                this.timeoutID = setTimeout(() => {
                    this.setState({isWatched: true});
                    this.renderButton();
                }, 30000);
            }
        } else if (this.props.stepData?.params.view_type === "presentation") {
            this.renderButton();
            this.timeoutID = setTimeout(() => {
                this.setState({isWatched: true});
                this.renderButton();
            }, 30000);
        };
        this.handleSaveFlow();
    };

    handleScrollWatched = () => {
        if (this.state.target.parentElement.parentElement.scrollTop + document.body.clientHeight > this.state.target.clientHeight) {
            this.state.target.parentElement.parentElement.removeEventListener('scroll', this.handleScrollWatched);
            this.handleWatched();
        }
    };

    handleRemoveEventListeners = () => {
        if (this.state.target) {
            this.props.stepData?.name === "video"
                ? this.state.target.removeEventListener('ended', this.handleWatched, true)
                : this.state.target.removeEventListener('scroll', this.handleScrollWatched);
        }
    };

    handleSkip = () => {
        this.handleRemoveEventListeners();
        this.props.handleNextStep();
    };

    onError = (e) => {
        setLog('Ошибка загрузки файла DocumentAndVideoContainer', e);
        this.handleSkip();
    };

    handleSaveFlow = () => {
        let statePreshift = this.props.preshift;
        let datumUhrzeit = new Date(),
            event_end_dt = "@" + Math.round(datumUhrzeit.getTime() / 1000),
            stepUuid = this.props.stepData.uuid,
            stepName = this.props.stepData.name,
            start_time = '@' + Math.round(this.state.event_dt.getTime() / 1000),
            data = {
                uuid: stepUuid,
                name: stepName,
                start_date_time: start_time,
                elapsed_time: event_end_dt
            },
            flow_history = statePreshift.flow.history.steps;
            flow_history.push(data);

        preshiftManage.saveFlow({ history: {...statePreshift.flow.history} });
    };

    renderButton = () => {
        let came_from = preshiftManage.getCameFrom();
        if (came_from !== 'startup' && this.props.activeStep === this.props.listEducation[this.props.activeEducation].listStep.length - 1 &&
            this.props.activeEducation === this.props.listEducation.length - 1) {
                this.props.renderAdditionalButton(
                    <div className="d-flex w-100 justify-content-end">
                        <Button className="MuiButton Btn Btn_success"
                                onClick={() => {
                                    if (this.state.is_exit_timer) {
                                        this.props.handleNextStep();
                                        this.props.renderAdditionalButton(null); }}}>
                            <FontAwesomeIcon className="mr-4" icon={faForward}/>
                            {this.props.t('Done')}
                            {this.state.isWatched && came_from === "electronic-courses" &&
                                <ExitTimer theme_name={this.props.app.theme_name} goOut={this.props.handleNextStep} handleExitTimer={this.handleExitTimer}/>}
                        </Button>
                        {came_from !== "electronic-courses" &&
                            <Button className="MuiButton Btn Btn_success ml-3"
                                    onClick={() => {
                                        if (this.state.is_exit_timer) {
                                            this.props.handleFinish();
                                            this.props.renderAdditionalButton(null); }}}>
                                <FontAwesomeIcon className="mr-4" icon={faForward}/>
                                {this.props.t('Finish and exit')}
                                {this.state.isWatched && <ExitTimer theme_name={this.props.app.theme_name} goOut={this.props.handleFinish} handleExitTimer={this.handleExitTimer}/>}
                            </Button>}
                    </div>);
        } else {
            this.props.renderAdditionalButton(
                <div className="d-flex w-100 justify-content-end">
                    <Button className="MuiButton Btn Btn_success"
                            onClick={() => {
                                if (this.state.is_exit_timer) {
                                    this.props.handleNextStep();
                                    this.props.renderAdditionalButton(null); }}}>
                        <FontAwesomeIcon className="mr-4" icon={faForward}/>
                        {this.props.activeStep !== this.props.listEducation[this.props.activeEducation].listStep.length - 1 ?
                                this.props.t('Next') :
                                (this.props.activeEducation !== this.props.listEducation.length - 1) ?
                                    this.props.t('Proceed') : this.props.t('Finish and exit')}
                        {((this.props.activeStep === this.props.listEducation[this.props.activeEducation].listStep.length - 1) &&
                        (this.props.activeEducation === this.props.listEducation.length - 1))
                            ? this.state.isWatched && <ExitTimer theme_name={this.props.app.theme_name} goOut={this.props.handleFinish} handleExitTimer={this.handleExitTimer}/>
                            : this.state.isWatched && <ExitTimer theme_name={this.props.app.theme_name} goOut={this.props.handleNextStep} handleExitTimer={this.handleExitTimer}/>}
                    </Button>
                </div>);
        }
    };

    render() {
        return <DocumentAndVideo {...this.props} handleWatched={this.handleWatched} onError={this.onError} />
    };
}

const mapStateToProps = (store) => {
    return {
        load: store.loadState,
        preshift: store.preshiftState,
    }
};

export default connect(mapStateToProps)(withRouter(withTranslation()(DocumentAndVideoContainer)));