import React, {Component} from "react";
import {connect} from "react-redux";
import {withTranslation} from "react-i18next";
import {pageLoadManageActions as loader} from "../../../reducers/load";
import apiLibrary from "../../../api/apiLibrary";
import {addBreamb, resetBreamb} from "../../../reducers/breams-manage";
import userManage from '../../../reducers/user-manage'
import {notify} from "../../blocks/Notify";
import LibraryMaterial from "../../screens/library/LibraryMaterial";
import LibrarySignature from '../../screens/library/LibrarySignature'

class LibraryMaterialContainer extends Component {
    constructor(props) {
        super(props);

        resetBreamb();

        this.state = {
            file: '',
            material: {},
            isView: false,
            content: LibraryMaterial
        }
    }

    componentDidMount() {
        addBreamb({title: this.props.t('Personal Area')});
        addBreamb({title: this.props.t('Library')});
        this.getMaterial(this.props.match.params.id);
        this.props.important_library && notify(this.props.t('Check out the important material'), 'warning');
    }

    setMarkRead = (uuid, file, fileName) => {
        this.setState({isView: true});
        apiLibrary.setMarkRead(this.props.user.data.id, uuid, file, fileName);
    }

    handleEndVideo = () => {
        setTimeout(() => {
            let materialContent = document.querySelector('.Material-FileViewer video');
            materialContent.autoplay = 'autoplay';
            materialContent.addEventListener('ended', () => {
                materialContent.pause();
                // materialContent.currentTime = materialContent.duration - 1;
                !this.state.material.metaFields?.use_signature && this.setMarkRead(this.props.match.params.id);
            });
        }, 1);
    }

    changeStep = () => {
        this.setState({content: this.state.content === LibraryMaterial ? LibrarySignature : LibraryMaterial})
    }

    handleEndDocument = () => setTimeout(() => !this.state.material.metaFields?.use_signature && this.setMarkRead(this.props.match.params.id), 1000);

    getMaterial = (uuid) => {
        return new Promise((resolve, reject) => {
            loader.start('content');
            let employee_id = this.props.user.data.id;
            apiLibrary.getMaterial(employee_id, uuid)
            .then(response => {
                let data = response.data;
                addBreamb({title: data.title});
                this.setState({material: {...data}}, () => {
                    switch (data.type) {
                        case 'text_document':
                            this.handleEndDocument();
                            break;
                        case 'simulated_situation':
                            this.handleEndVideo();
                            break;
                        case 'video_tutorial':
                            this.handleEndVideo();
                            break;
                        default:
                            console.log('Unknown type');
                            break;
                    }
                    resolve(response);
                });
            })
            .catch(e => {
                notify(this.props.t('File upload error'), 'error');
                this.props.important_library
                    ? this.handleChangeUserData()
                    : this.props.history.goBack()
            })
            .finally(() => loader.stop('content'));
        });
    }

    handleChangeUserData = async () => {
        const user_data = userManage.getData();
        const new_user_data = {
            ...user_data,
            important_library: null,
        };
        await userManage.authorized(new_user_data)
        .then(userManage.setIntervalGetExtData);
    };

    render() {
        const Content = this.state.content
        return <Content {...this.state} {...this.props} changeStep={this.changeStep} setMarkRead={this.setMarkRead} handleChangeUserData={this.handleChangeUserData} />;
    }
}

const mapStateToProps = (store) => {
    return {
        user: store.userState,
        load: store.loadState,
    };
};

export default connect(mapStateToProps)(withTranslation()(LibraryMaterialContainer));