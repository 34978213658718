import React, { useEffect, useRef, useState } from 'react'
import Lottie from 'lottie-react'
import paint_lottie from '../../../data/icons/signature_lottie.json'
import tap_lottie from '../../../data/icons/tap_screen.json'
import Breams from '../../blocks/Breams'
import MainNav from '../../blocks/MainNav'
import { Button, Container } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowLeft, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { NavLink } from 'react-router-dom'
import { setLog } from '../../../helpers/functions'

export default function LibrarySignature(props) {
    const [isShowCanvas, setIsShowCanvas] = useState(false)
    const [isShowButtons, setIsShowButtons] = useState(false)
    const [isTapLottie, setIsTapLottie] = useState(true)

    const handleClearCanvas = useRef(()=>{})
    const saveImgAndClearCanvas = useRef(()=>{})

    const createCanvas = () => {
        // Инициализируем html элементы
        const canvas = document.getElementById('signature_canvas');
        const ctx = canvas.getContext('2d');
        let rect = {};

        setTimeout(() => {
            // Устанавливаем размер холста
            canvas.setAttribute('width', canvas.parentElement.clientWidth);
            canvas.setAttribute('height', canvas.parentElement.clientHeight);

            // Получаем координаты холста
            rect = canvas.getBoundingClientRect();

            // Инициализируем стиль кисти
            ctx.lineWidth = 5;
        }, 1);

        let newDraw = false;

        // Рисование линий
        const drawLine = (x, y) => {
            ctx.lineTo(x, y);
            ctx.stroke();
        }

        // Добавляем позиции X и Y
        const recordMousePos = (e) => {
            const x = e.x - rect.left;
            const y = e.y - rect.top;
            drawLine(x, y);
        }

        const recordTachPos = (e) => {
            const touch = e.touches[0];
            const x = touch.pageX - rect.left;
            const y = touch.pageY - rect.top;
            if (x < 0 || x > rect.width || y < 0 || y > rect.height) {
                stopTachDrawing();
            } else {
                drawLine(x, y);
            }
        }

        // Очистить холст при рисовании (скрытое действие)
        const clearCanvas = () => {
            if(newDraw) {
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                newDraw = false;
            }
            ctx.beginPath();
        }

        // Рисование
        const paint = () => {
            clearCanvas();
            canvas.onmousemove = recordMousePos;
        };

        const tachPaint = (e) => {
            e.preventDefault();
            clearCanvas();
            canvas.addEventListener("touchmove", recordTachPos);
        }

        // Остановка рисования
        const stopMouseDrawing = () => {
            if (canvas.onmousemove) {
                canvas.onmousemove = null;
                setIsShowButtons(true);
            }
        }

        const stopTachDrawing = () => {
            canvas.removeEventListener("touchmove", recordTachPos);
            setIsShowButtons(true);
        }

        // Очистить холст по нажатию кнопки
        handleClearCanvas.current = () => {
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.beginPath();
            setIsShowButtons(false);
        }

            // Сохранение файла и очистка canvas
        saveImgAndClearCanvas.current = () => {
            const file = canvas.toDataURL('image/png');
            ctx.clearRect(0, 0, canvas.width, canvas.height);
            ctx.beginPath();
            return file;
        };

        // При нажатии на мышь над canvas
        canvas.addEventListener("mousedown", paint);
        // Когда мышь отпущена над canvas
        canvas.addEventListener("mouseup", stopMouseDrawing);
        // Когда мышь уходит за край canvas
        canvas.addEventListener("mouseout", stopMouseDrawing);

        // Добавляем рисование на сенсорном экране
        canvas.addEventListener("touchstart", tachPaint);
        // Удаляем рисование на сенсорном экране
        canvas.addEventListener("touchend", stopTachDrawing);
    };

    useEffect(() => {
        if (isShowCanvas) createCanvas()
    }, [isShowCanvas])

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: isTapLottie ? tap_lottie : paint_lottie,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice',
        },
    }

    const defaultBackUrl = '/library/video_tutorial,simulated_situation,text_document'
    const historyBack = (defaultUrl) => {
        if (history.length > 2) {
            props.history.goBack();
        } else if (defaultUrl) {
            props.history.push(defaultUrl);
        } else {
            props.history.push('/');
        }
    }

    const getFile = (screen, name) => {
        return new Promise((resolve, reject) => {
            if (screen) {
                fetch(screen)
                .then(res => res.blob())
                .then(blob => resolve(new File([blob], `${name}.png`, blob)))
                .catch(reject);
            } else {
                reject('Не было касания экрана пальцем или мышью');
            }
        });
    }

    const handleResult = async () => {
        const uuid = props.material.uuid
        const file_name = `signature_${uuid}`
        const screenshot = saveImgAndClearCanvas.current()
        await getFile(screenshot, file_name)
        .then(file => {
            props.setMarkRead(uuid, file, file_name)
        })
        .catch(e => setLog(`Ошибка создания файла с подписью ${file_name}`, e))
        .finally(async () => {
            props.important_library
                ? await props.handleChangeUserData()
                : historyBack(defaultBackUrl)
        })
    }

    const handleShowCanvas = () => !isShowCanvas && setIsShowCanvas(true)

    return (
        <>
            <Breams/>
            <Container className='d-flex flex-column justify-content-center align-items-center h-100'>
                <h1 className='display-7 font-weight-bold'>{props.t('Sign up for viewing the material')}</h1>
                <div className="mb-3 w-100 h-75 d-flex justify-content-center align-items-center border-2-solid-black cursor-pointer" onClick={handleShowCanvas}>
                    {isShowCanvas
                        ? <canvas id='signature_canvas'></canvas>
                        : <Lottie
                            className="preshift__lottie mb-3"
                            {...defaultOptions}
                            style={{height: '50rem', width: '50rem'}}
                            onLoopComplete={() => setIsTapLottie(!isTapLottie)}
                            />}
                </div>
                {isShowCanvas &&
                    <div>
                        <Button id="signature_clear_bt" className="MuiButton Btn Btn_success text-center" onClick={handleClearCanvas.current}>
                            {props.t('Clear the signature field')}
                        </Button>
                    </div>}
            </Container>
            <MainNav>
                <Container className={`d-flex justify-content-between`}>
                    <Button onClick={props.changeStep} className={`Btn Btn_boring MuiButton`}>
                        <FontAwesomeIcon className="mr-4" icon={faArrowLeft}/>
                        <span>{props.t('Back')}</span>
                    </Button>
                    <Button onClick={handleResult} className={`Btn Btn_success MuiButton`} disabled={!isShowButtons}>
                        <span>{props.t('Done')}</span>
                    </Button>
                    <NavLink to={"/"} className={`Btn Btn_danger MuiButton`}>
                        <FontAwesomeIcon className="mr-4" icon={faSignOutAlt}/>
                        <span>{props.t('Exit')}</span>
                    </NavLink>
                </Container>
            </MainNav>
        </>
    )
}