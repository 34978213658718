import React, {Component} from 'react';
import {connect} from "react-redux";
import { isElectron } from 'react-device-detect'
import { Button, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faForward } from '@fortawesome/free-solid-svg-icons';
import Lottie from 'lottie-react';
import preshiftManage from "../../../../reducers/preshift-manager";
import ExitTimer from '../../../blocks/ExitTimer';
import configApp from "../../../../config/config";
import { setLog } from '../../../../helpers/functions';
import paint_lottie from "../../../../data/icons/signature_lottie.json";
import tap_lottie from "../../../../data/icons/tap_screen.json";
import { notify } from '../../../blocks/Notify'

class SignatureContainer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            event_dt: new Date(),
            is_exit_timer: true,
            is_show_canvas: false,
            is_show_buttons: false,
            is_tap_lottie: true,
            is_required: props.stepData.params?.hasOwnProperty('is_required') && props.stepData.params.is_required,
            devMode: configApp.device_settings?.devMode ? true : false,
        };
    }

    skipListener = (event) => {
        if (isElectron && event.ctrlKey && event.key.toLowerCase() === 'n') {
            this.props.handleNextStep()
        }
    };

    componentDidMount() {
        this.renderButtons();
        document.addEventListener('keydown', this.skipListener);
        this.state.is_required && notify(this.props.t('This step is required, if the time expires, the result will not be counted'), 'warning')
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.skipListener);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.is_show_buttons !== this.state.is_show_buttons) this.renderButtons();
        if (prevState.is_show_canvas !== this.state.is_show_canvas) this.createCanvas();
    }

    createCanvas = () => {
        if (this.state.is_show_canvas) {
            // Инициализируем html элементы
            const canvas = document.getElementById('signature_canvas');
            const ctx = canvas.getContext('2d');
            let rect = {};

            setTimeout(() => {
                // Устанавливаем размер холста
                canvas.setAttribute('width', canvas.parentElement.clientWidth);
                canvas.setAttribute('height', canvas.parentElement.clientHeight);

                // Получаем координаты холста
                rect = canvas.getBoundingClientRect();

                // Инициализируем стиль кисти
                ctx.lineWidth = 5;
            }, 1);

            let newDraw = false;

            // Рисование линий
            const drawLine = (x, y) => {
                ctx.lineTo(x, y);
                ctx.stroke();
            }

            // Добавляем позиции X и Y
            const recordMousePos = (e) => {
                const x = e.x - rect.left;
                const y = e.y - rect.top;
                drawLine(x, y);
            }

            const recordTachPos = (e) => {
                const touch = e.touches[0];
                const x = touch.pageX - rect.left;
                const y = touch.pageY - rect.top;
                if (x < 0 || x > rect.width || y < 0 || y > rect.height) {
                    stopTachDrawing();
                } else {
                    drawLine(x, y);
                }
            }

            // Очистить холст при рисовании (скрытое действие)
            const clearCanvas = () => {
                if(newDraw) {
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                    newDraw = false;
                }
                ctx.beginPath();
            }

            // Рисование
            const paint = () => {
                clearCanvas();
                canvas.onmousemove = recordMousePos;
            };

            const tachPaint = (e) => {
                e.preventDefault();
                clearCanvas();
                canvas.addEventListener("touchmove", recordTachPos);
            }

            // Остановка рисования
            const stopMouseDrawing = () => {
                if (canvas.onmousemove) {
                    canvas.onmousemove = null;
                    this.setState({is_show_buttons: true});
                }
            }

            const stopTachDrawing = () => {
                canvas.removeEventListener("touchmove", recordTachPos);
                this.setState({is_show_buttons: true});
            }

            // Очистить холст по нажатию кнопки
            this.handleClearCanvas = () => {
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                ctx.beginPath();
                this.setState({is_show_buttons: false});
            }

            // Сохранение файла и очистка canvas
            this.saveImgAndClearCanvas = () => {
                const file = canvas.toDataURL('image/png');
                ctx.clearRect(0, 0, canvas.width, canvas.height);
                ctx.beginPath();
                return this.state.is_show_buttons ? file : '';
            };

            // При нажатии на мышь над canvas
            canvas.addEventListener("mousedown", paint);
            // Когда мышь отпущена над canvas
            canvas.addEventListener("mouseup", stopMouseDrawing);
            // Когда мышь уходит за край canvas
            canvas.addEventListener("mouseout", stopMouseDrawing);

            // Добавляем рисование на сенсорном экране
            canvas.addEventListener("touchstart", tachPaint);
            // Удаляем рисование на сенсорном экране
            canvas.addEventListener("touchend", stopTachDrawing);
        }
    };

    renderButtons = () => {
        const came_from = preshiftManage.getCameFrom();
        if (came_from !== 'startup' && this.props.activeStep === this.props.listEducation[this.props.activeEducation].listStep.length - 1 &&
                this.props.activeEducation === this.props.listEducation.length - 1) {
                this.props.renderAdditionalButton(
                    <div className="d-flex w-100 justify-content-end">
                        {(this.props.listEducation[this.props.activeEducation].listStep[this.props.activeStep].can_skip || this.state.devMode) &&
                            <Button className="MuiButton Btn Btn_success" onClick={this.props.handleNextStep}>
                                <FontAwesomeIcon className="mr-4" icon={faForward}/>
                                {this.props.t('Skip')}
                            </Button>}
                        <Button className="MuiButton Btn Btn_success ml-3"
                                disabled={!this.state.is_show_buttons}
                                onClick={() => {
                                    if (this.state.is_exit_timer) {
                                        this.handleResult(this.props.handleNextStep);
                                        this.props.renderAdditionalButton(null); }}}>
                            <FontAwesomeIcon className="mr-4" icon={faForward}/>
                            {this.props.t('Done')}
                            {came_from === "electronic-courses" &&
                                <ExitTimer
                                    theme_name={this.props.app.theme_name}
                                    goOut={() => this.handleResult(this.props.handleNextStep)}
                                    handleExitTimer={this.handleExitTimer} sec={60} />}
                        </Button>
                        {came_from !== "electronic-courses" &&
                            <Button className="MuiButton Btn Btn_success ml-3"
                                    disabled={!this.state.is_show_buttons}
                                    onClick={() => {
                                        if (this.state.is_exit_timer) {
                                            this.handleResult(this.props.handleFinish);
                                            this.props.renderAdditionalButton(null); }}}>
                                <FontAwesomeIcon className="mr-4" icon={faForward}/>
                                {this.props.t('Finish and exit')}
                                <ExitTimer
                                    theme_name={this.props.app.theme_name}
                                    goOut={() => this.handleResult(this.props.handleFinish)}
                                    handleExitTimer={this.handleExitTimer} sec={60} />
                            </Button>}
                    </div>);
            } else {
                let btn_text = ''
                let goOut = this.props.handleNextStep;
                if (this.props.activeStep !== this.props.listEducation[this.props.activeEducation].listStep.length - 1) {
                    btn_text = this.props.t('Next');
                } else {
                    if (this.props.activeEducation !== this.props.listEducation.length - 1) {
                        btn_text = this.props.t('Proceed');
                    } else {
                        btn_text = this.props.t('Finish and exit');
                        goOut = this.props.handleFinish;
                    }
                }
                this.props.renderAdditionalButton(
                    <div className="d-flex w-100 justify-content-end">
                        {(this.props.listEducation[this.props.activeEducation].listStep[this.props.activeStep].can_skip || this.state.devMode) &&
                            <Button className="MuiButton Btn Btn_success" onClick={this.props.handleNextStep}>
                                <FontAwesomeIcon className="mr-4" icon={faForward}/>
                                {this.props.t('Skip')}
                            </Button>}
                        <Button className="MuiButton Btn Btn_success ml-3"
                                disabled={!this.state.is_show_buttons}
                                onClick={() => {
                                    if (this.state.is_exit_timer) {
                                        this.handleResult(goOut);
                                        this.props.renderAdditionalButton(null); }}}>
                            <FontAwesomeIcon className="mr-4" icon={faForward}/>
                            {btn_text}
                            <ExitTimer
                                theme_name={this.props.app.theme_name}
                                goOut={() => this.handleResult(goOut)}
                                handleExitTimer={this.handleExitTimer} sec={60} />
                        </Button>
                    </div>);
            }
    }

    handleExitTimer = () => this.setState({ is_exit_timer: false });

    saveImgAndClearCanvas = () => '';

    getFile(screen, name) {
        return new Promise((resolve, reject) => {
            if (screen) {
                fetch(screen)
                .then(res => res.blob())
                .then(blob => resolve(new File([blob], `${name}.png`, blob)))
                .catch(reject);
            } else {
                reject('Не было касания экрана пальцем или мышью');
            }
        });
    }

    handleResult = async (goOut) => {
        const file_name = `signature_${this.props.activeStep}`;
        const screenshot = this.saveImgAndClearCanvas();
        const stepName = this.props.stepData.name;
        await this.getFile(screenshot, file_name)
        .then(file => {
            let flow = {};
            flow[file_name] = file;
            let statePreshift = this.props.preshift;
            let datumUhrzeit = new Date(),
                event_end_dt = "@" + Math.round(datumUhrzeit.getTime() / 1000),
                stepUuid = this.props.stepData.uuid,
                start_time = '@' + Math.round(this.state.event_dt.getTime() / 1000),
                data = {
                    uuid: stepUuid,
                    name: stepName,
                    start_date_time: start_time,
                    elapsed_time: event_end_dt,
                    file: file.name,
                },
                flow_history = statePreshift.flow.history.steps;
                flow_history.push(data);

            preshiftManage.saveFlow({ history: {...statePreshift.flow.history} });
            preshiftManage.saveFlow(flow);
        })
        .catch(e => {
            setLog(`Ошибка создания файла ${stepName}`, e)
            this.state.is_required && notify(this.props.t('The time spent on the signature has expired, the result has been canceled and not counted'), 'error')
        })
        .finally(() => (this.state.is_required && !this.state.is_show_buttons) ? this.props.history.push('/') : goOut())
    }

    handleShowCanvas = () => !this.state.is_show_canvas && this.setState({is_show_canvas: true});

    render = () => {
        const defaultOptions = {
            loop: true,
            autoplay: true,
            animationData: this.state.is_tap_lottie ? tap_lottie : paint_lottie,
            rendererSettings: {
                preserveAspectRatio: 'xMidYMid slice',
            },
        };
        return (
            <Container className='d-flex flex-column justify-content-center align-items-center h-100'>
                <div className="mb-3 w-100 h-75 d-flex justify-content-center align-items-center border-2-solid-black" onClick={this.handleShowCanvas}>
                    {this.state.is_show_canvas
                        ? <canvas id='signature_canvas'></canvas>
                        : <Lottie
                            className="preshift__lottie mb-3"
                            {...defaultOptions}
                            style={{height: '50rem', width: '50rem'}}
                            onLoopComplete={() => this.setState({is_tap_lottie: !this.state.is_tap_lottie})}
                            />}
                </div>
                {this.state.is_show_canvas &&
                    <div>
                        <Button id="signature_clear_bt" className="MuiButton Btn Btn_success text-center" onClick={this.handleClearCanvas}>
                            {this.props.t('Clear the signature field')}
                        </Button>
                    </div>}
            </Container>
        )
    }
}

const mapStateToProps = (store) => {
    return {
        preshift: store.preshiftState,
    }
};

export default connect(mapStateToProps)(SignatureContainer);