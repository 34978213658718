import React, { useEffect, useState } from 'react';
import {useSelector} from "react-redux";
import FileViewer from "react-file-viewer";
import {Image, Button} from "react-bootstrap";
import ReactPlayer from 'react-player';
import parse from "html-react-parser";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';
import Loader from "../../blocks/Loader";
import Content from "../../blocks/Content";
import FatalError from "../FatalError";
import {ext, setLog} from "../../../helpers/functions";
import { addBreamb, goBackStep } from '../../../reducers/breams-manage';
import apiEmployee from '../../../api/apiEmployee';
import userManage from '../../../reducers/user-manage';

export default function Notices(props) {
    const user = useSelector(store => store.userState);
    const [showDescription, setShowDescription] = useState(false);
    const description = props.notice.description;
    const actions = props.notice.actions;
    const btn_count = actions.length;
    const content_type = props.notice.content['content-type'];
    const notice_content = props.notice.content.content;
    useEffect(()=> {
        let title = props.notice.title.slice(0, 30);
        if (props.notice.title.length > 20) title += "...";
        addBreamb({title: props.t(title)});
        return goBackStep;
    }, []);
    const handleButtonClick = (index) => {
        props.onSelectAction(index);
        user.isAuth && apiEmployee.auth(user.data.uuid, true).then(res => userManage.authorized(res.data[0]));
    };
    const nodeActions = actions.map((action, index) =>
        <Button key={index} onClick={() => handleButtonClick(index)}
            className={`Btn MuiButton btn btn-${props.colorActions[action.name]} p-4 border-0 w-100 align-items-center justify-content-center`}>
            {action.title}
        </Button>);

    const onError = (e) => setLog('Ошибка при загрузке файла Notices', e);

    let content = null;
    if (content_type === 'document') {
        const fileType = ext(notice_content);
        content = notice_content &&
            <div className="Material-FileViewer">
                <FileViewer
                    id="FileViewer"
                    autoPlay={true}
                    fileType={fileType}
                    filePath={notice_content}
                    errorComponent={FatalError}
                    onError={onError} />
            </div>
    } else if (content_type === 'video') {
        content = notice_content &&
            <div className="notice_img_video_div">
                <ReactPlayer
                    id="FileViewer"
                    playing={true}
                    url={notice_content}
                    width="100%"
                    height="100%"
                    controls
                    config={{ file: { attributes: { controlsList: 'nodownload', disablePictureInPicture: true } } }} />
            </div>
    } else if (content_type === 'photo') {
        // TODO: Почему-то при выводе через FileViewer ругается на CORS, поэтому вынес в компонент Image
        content = <div className="notice_img_video_div">
                    <Image src={notice_content} alt='notice_img' />
                  </div>
    } else {
        content = parse(notice_content);
    };

    return <div className={"Screen quest container"}>
            <Loader isLoading={props.load.isLoading} loadId={"item"} justifyContent="center">
                <Content>
                    <div className={"display-9"}>
                        {description && description.length > 50 && showDescription
                            ? <p>{description}</p>
                            : <p>{description?.slice(0, 150)}{description?.length > 150 && "..."}</p>}
                        {description && description.length > 150 &&
                            <div className="descriptionShowBtn">
                                <button type="button" className="descriptionShowButtton" onClick={() => setShowDescription(!showDescription)}>
                                    <FontAwesomeIcon icon={showDescription ? faChevronUp : faChevronDown} />
                                </button>
                            </div>}
                        <div className={"display-9"}>
                            {content}
                        </div>
                    </div>
                </Content>
                <div className={`nodeActions nodeActionsGridCol-${btn_count}`}>
                    {nodeActions}
                </div>
            </Loader>
           </div>
};