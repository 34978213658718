import React from "react";
import {Route} from "react-router-dom";
import {connect} from "react-redux";
import PinCodeContainer from "./PinCodeContainer";
import EmpLvlContainer from "./EmpLvlContainer";
import QuestionnairesListContainer from "./QuestionnairesListContainer";
import StartupStepper from "../steppers/StartupStepper";
import NoticesStepper from "../steppers/NoticesStepper";
import LibraryMaterialContainer from './library/LibraryMaterialContainer'

function PrivateRoute({component: Component, ...rest}) {
  const render = (props) => {
    if (rest.user.isAuth && !rest.location.search) {
      if (rest.app.modules.includes("pin_code") &&
          rest.app.use_pin_code &&
          !rest.user.isPinCode) {
        return <PinCodeContainer />;
      } else {
        if (rest.user.data.viewRisks) {
          return <EmpLvlContainer {...rest} {...props} />
        } else if (rest.user.data?.hasOwnProperty("important_survey") &&
            rest.user.data.important_survey !== null &&
            rest.user.data.important_survey.appointment_id &&
            rest.user.data.important_survey.survey_uuid) {
          return <QuestionnairesListContainer />
        } else if (props.location.pathname !== "/notices" &&
            rest.user.data?.hasOwnProperty("block_notifications") &&
            rest.user.data.block_notifications.length > 0) {
          return <NoticesStepper />
        } else if (rest.user.data?.hasOwnProperty("important_library") &&
            rest.user.data.important_library !== null &&
            rest.user.data.important_library.uuid) {
          return <LibraryMaterialContainer match={{params: {id: rest.user.data.important_library.uuid}}} important_library={true} />
        } else {
          return <Component {...rest} {...props} />;
        }
      }
    } else {
      return <StartupStepper />
    }
  };
  return <Route {...rest} render={render} />;
};

const mapStateToProps = (store) => {
  return {
    user: store.userState,
    app: store.appState,
  };
};

export default connect(mapStateToProps)(PrivateRoute);