import {Animated} from "react-animated-css";
import React from "react";
import {connect} from "react-redux";
import {isElectron} from "react-device-detect";
import {Container} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowLeft, faSignOutAlt} from "@fortawesome/free-solid-svg-icons";
import {NavLink, withRouter} from "react-router-dom";
import isCordova from "is-cordova";
import {withTranslation} from "react-i18next";
import Breams from "../blocks/Breams";
import StepperNav from "../blocks/StepperNav";
import MainNav from "../blocks/MainNav";
import {errorNotify, isValidHttpUrl, setLog} from "../../helpers/functions";
import preshiftManage from "../../reducers/preshift-manager";
import {clearStatePreshift} from "../../reducers/preshift";
import store from "../../localStorage";
import configApp from "../../config/config";
import moduleAlcotester from "../../externalHardware/moduleAlcotester/moduleAlcotester";
import apiPreshift from "../../api/apiPreshift";
import Loader from "../blocks/Loader";
import {pageLoadManageActions as loader} from "../../reducers/load";
import ManageStepper from "./ManageStepper";
import QuestionContainer from "../containers/questions/flow/QuestionContainer";
import BreathalyzeContainer from "../containers/questions/flow/BreathalyzeContainer";
import FrontCameraContainer from "../containers/questions/flow/FrontCameraContainer";
import SignatureContainer from "../containers/questions/flow/SignatureContainer";
import OverviewCameraContainer from "../containers/questions/flow/OverviewCameraContainer";
import DocumentAndVideoContainer from "../containers/questions/flow/DocumentAndVideoContainer";
import {addBreamb, resetBreamb} from "../../reducers/breams-manage";
import { notify } from "../blocks/Notify";
import ExtraEducationsResults from "../containers/questions/ExtraEducationsResults";
import QuestionNotifyContainer from "../containers/questions/QuestionNotifyContainer";

class QuestionStepper extends ManageStepper {
    constructor(props) {
        super(props);
        preshiftManage.setIsTakeOverviewPhoto(false);
        preshiftManage.setIsTakeFrontPhoto(false);
        this.state = {
            show_question_notify: false,
            activeStep: 0, // текущий индекс шага(из суммы шагов со всех обучений)
            educations: [], // обучения
            currentTitle: '',
            params: [],
            listStep: [],
            nextTitle: '',
            stepContent: '',
            isAsyncOverviewCameraStepDone: false,
            isAsyncFrontCameraStepDone: false,
            activeEducation: 0, // текущий индекс обучения
            webListStep: ['question', 'document', 'video', 'signature'], // шаги только для web версии приложения
            electronListStep: ['question', 'breathalyze', 'overviewCamera', 'frontCamera', 'document', 'video', 'signature'], // шаги только для electron(windows/linux) версии приложения
            cordovaListStep: ['question', 'frontCamera', 'document', 'video', 'signature'], // шаги только для cordova(android/ios) версии приложения
            listContent: {
                breathalyze: BreathalyzeContainer,
                question: QuestionContainer,
                overviewCamera: OverviewCameraContainer,
                frontCamera: FrontCameraContainer,
                document: DocumentAndVideoContainer,
                video: DocumentAndVideoContainer,
                signature: SignatureContainer,
            },
        }

        this.breathalyze = false;
        this.overviewCamera = false;
        this.frontCamera = false;
        this.breathalyzeError = false;
        this.overviewCameraError = false;
        this.frontCameraError = false;
        this.overviewCameraScreenShot = '';
        this.frontCameraScreenShot = '';

        resetBreamb();
    }

    componentDidMount() {
        Promise.all([this.isFrontCameraOn(), this.isOverviewCameraOn(), this.isBreathalyzeOn()])
        .then(response => {
            this.frontCamera = response[0];
            this.overviewCamera = response[1];
            this.breathalyze = response[2];
        })
        .then(() => {
            this.getExamine()
            .then(response =>
                this.prepareEducations(response.data)
                .then(() => {
                    if (this.state.educations.length > 0) {
                        this.prepareToRender();
                        let flow = this.prepareFlowEducation();
                        preshiftManage.saveFlow(flow);
                    }
                }));
        });
    }

    /*
     Логирует ошибки
     */

    errorLogger = (e, error_from) => {
        if (error_from === 'breathalyze') this.breathalyzeError = true;
        if (error_from === 'overviewCamera') this.overviewCameraError = true;
        if (error_from === 'frontCamera') this.frontCameraError = true;
        const info = {
            text:`Ошибка с оборудованием ${error_from} в QuestionStepper`,
            user: this.props.user.data.title
        };
        setLog(info, e);
    };

    /**
     * На основе обучений подготавливает
     * рендер к текущему шагу обучения: устанавливает хлеб.крошки, заголовки, контент...
     */
    prepareToRender = () => {
        let education = this.state.educations[this.state.activeEducation],
            listStep = [],
            currentTitle = '',
            nextTitle = '',
            stepContent;

        if (education) {
            currentTitle = this.currentTitle();
            this.randBreamb();
            listStep = this.listStep();
            nextTitle = this.nextTitle();
            if (education.isAsyncFrontCameraStep && !this.state.isAsyncFrontCameraStepDone) {
                this.screenshot(education.asyncFrontCameraStep);
            }
            if (education.isAsyncOverviewCameraStep && !this.state.isAsyncOverviewCameraStepDone) {
                this.screenshot(education.asyncOverviewCameraStep);
            }
        }

        stepContent = this.stepContent(listStep);

        this.setState({
            listStep: listStep,
            stepContent: stepContent,
            currentTitle: currentTitle,
            nextTitle: nextTitle,
        });
    }

    randBreamb = () => {
        let activeStep = this.getActiveStep();

        if (this.state.educations[this.state.activeEducation].type === "extra_education_flow") {
            addBreamb({title: this.props.t('Extra education')});
        } else if (this.state.educations[this.state.activeEducation].type === "simpleCheck") {
            addBreamb({title: this.props.t('Pre-shift exam')});
        } else {
            addBreamb({title: this.props.t('Electronic courses')});
            addBreamb({title: this.props.course_title});
        }

        if (this.state.educations[this.state.activeEducation].listStep.length > 0) {
            addBreamb({title: this.state.educations[this.state.activeEducation].listStep[activeStep].title});
        }
    }

    /**
     * Возвращает шаги обучений в одном массиве
     * @returns {*[]}
     */
    listStep = () => {
        let listStep = [];
        this.state.educations.forEach(education => listStep = listStep.concat(education.listStep));
        return listStep;
    }

    /**
     * Возвращает заголовок обучения
     * @returns {*}
     */
    currentTitle = () => {
        let currentTitle = null;

        if (this.state.educations[this.state.activeEducation].type === "extra_education_flow") {
            currentTitle = this.props.t('Extra education');
        } else if (this.state.educations[this.state.activeEducation].type === "simpleCheck") {
            currentTitle = this.props.t('Pre-shift exam');
        } else {
            currentTitle = this.props.t('Electronic courses');
        }

        return currentTitle;
    }

    /**
     * Возвращает заголовок следующего обучения
     * @returns {string}
     */
    nextTitle = () => {
        let nextTitle = '',
            activeEducation = this.state.activeEducation;

        if (this.state.educations[activeEducation + 1]) {
            if (this.state.educations[activeEducation + 1].type === "extra_education_flow") {
                nextTitle = this.props.t('Extra education');
            } else if (this.state.educations[activeEducation + 1].type === "simpleCheck") {
                nextTitle = this.props.t('Pre-shift exam');
            } else {
                nextTitle = this.props.t('Electronic courses');
            }
        }

        return nextTitle;
    }

    /**
     * Возвращает контент текущего шага
     * @param listStep
     * @returns {null}
     */
    stepContent = (listStep) => {
        let stepContent = null;

        if (listStep.length > 0) {
            listStep.forEach((step, indexStep) => {
                if (indexStep === this.state.activeStep) {
                    document.title = step.title;
                    stepContent = listStep[indexStep].content;
                    return true;
                }
            });
        }

        return stepContent;
    }

    /**
     * Подготавливает структуру flow, которая может быть передана серверу
     * @returns {{}}
     */
    prepareFlowEducation = () => {
        let flow = {};
        let datumUhrzeit = new Date();
        let start_dt = '@' + Math.round(datumUhrzeit.getTime() / 1000);
        flow.history = {};
        flow.history.flow = this.state.educations[this.state.activeEducation].uuid;
        flow.history.employee = this.props.user.data.uuid;
        flow.history.params = this.state.params[this.state.activeEducation];
        flow.history.start_dt = start_dt;
        flow.history.steps = [];

        return flow;
    }

    /**
     * Подготавливает состояние обучений
     * @param data
     * @returns {Promise<unknown>}
     */
    prepareEducations = (data) => {
        return new Promise((resolve, reject) => {
            const educations = [];
            const params = [];

            data.forEach((item, k) => {
                params.push(item.params);
                const appointment = item.uuid;
                const education = this.prepareListStep(item.steps, item.type, appointment);
                educations.push(education);
            });

            const show_question_notify = (!this.props.flow_uuid && educations.length > 1) ? true : false;
            this.setState({
                educations: educations,
                params: params,
                show_question_notify: show_question_notify,
            }, () => resolve(educations));
        });
    }

    /**
     * Подготавливает шаги обучения
     * @param obListStep
     * @param type
     * @param appointment
     * @returns {{listStep: *[]}}
     */

    isBreathalyzeOn = () => {
        const breathalyze = this.props.app.is_breathalyzer;
        const settingsBreathalyze = {...configApp.device_settings.breathalyzer};
        const error_text = 'Alcotester not found';
        const error_from = 'breathalyze';
        return new Promise((resolve, reject) => {
            if (isElectron) {
                loader.start('stepper');
                if (breathalyze || breathalyze === 1) {
                    let alcotester =  new moduleAlcotester(settingsBreathalyze, () => {});
                    alcotester.checkStatus()
                    .then(result => {
                        result
                            ? alcotester.close().catch(console.log)
                            : this.errorLogger(error_text, error_from);
                        resolve(result);
                    })
                    .catch(e => {
                        this.errorLogger(e, error_from);
                        resolve(false);
                    })
                } else {
                    resolve(false);
                }
            } else {
                resolve(false);
            }
        })
    }

    isFrontCameraOn = () => {
        const frontCamera = this.props.app.front_camera;
        const error_text = 'Front camera not found';
        const error_from = 'frontCamera';
        return new Promise((resolve, reject) => {
            if (isElectron) {
                loader.start('stepper');
                if (frontCamera === 'internal') {
                    navigator.mediaDevices.enumerateDevices()
                    .then(response => {
                        let res = [];
                        response.forEach(el => res.push(el.kind));
                        if (res.includes("videoinput")) {
                            navigator.mediaDevices.getUserMedia({video: true, audio: false})
                            .then(async stream => {
                                const mediaStreamTrack = stream.getVideoTracks()[0];
                                const imageCapture = new ImageCapture(mediaStreamTrack);
                                await imageCapture.takePhoto()
                                .then(blob => {
                                    const reader = new FileReader();
                                    reader.readAsDataURL(blob);
                                    reader.onloadend = () => {
                                        this.frontCameraScreenShot = reader.result;
                                        resolve(true);
                                    };
                                })
                                .catch(() => resolve(false))
                                .finally(() => {
                                    mediaStreamTrack.stop();
                                    stream.removeTrack(mediaStreamTrack);
                                });
                            }).catch(() => resolve(false));
                        } else {
                            resolve(false);
                            this.errorLogger(error_text, error_from);
                        }
                    }).catch(e => {
                        this.errorLogger(e, error_from);
                        resolve(false);
                    })
                
                } else if (isValidHttpUrl(frontCamera)) {
                    try {
                        const http = require("http");
                        http.get(frontCamera, (res) => {
                                const { statusCode } = res;
                                res.responseType = "arraybuffer";

                                let error;

                                if (statusCode !== 200) error = new Error('Request Failed.\n' + `Status Code: ${statusCode}`);
                                if (error) {
                                    res.resume();
                                    resolve(false);
                                    this.errorLogger(error, error_from);
                                }

                                let rawData = [];
                                res.on('data', (chunk) => rawData.push(chunk));
                                res.on('end', () => {
                                    try {
                                        if (rawData.length > 0) {
                                            this.frontCameraScreenShot = 'data:image/jpg;base64,' + Buffer.concat(rawData).toString('base64');
                                            resolve(true);
                                        } else {
                                            resolve(false);
                                            this.errorLogger(error_text, error_from);
                                        }
                                    } catch (e) {
                                        resolve(false);
                                        this.errorLogger(error_text, error_from);
                                    }
                                });
                                }).on('error', (e) => {
                                    resolve(false);
                                    this.errorLogger(error_text, error_from);
                                    });
                    } catch (error) {
                        resolve(false);
                        this.errorLogger(error, error_from);
                    }
                } else {
                    resolve(false);
                }
            } else {
                resolve(false);
            }
        })
    }

    isOverviewCameraOn = () => {
        const viewCamera = this.props.app.view_camera;
        const error_text = 'Overview camera not found';
        const error_from = 'overviewCamera';
        return new Promise((resolve, reject) => {
            if (isElectron) {
                loader.start('stepper');
                if (isValidHttpUrl(viewCamera)) {
                    try {
                        const http = require("http");
                        http.get(viewCamera, (res) => {
                                const { statusCode } = res;
                                res.responseType = "arraybuffer";

                                let error;

                                if (statusCode !== 200) error = new Error('Request Failed.\n' + `Status Code: ${statusCode}`);
                                if (error) {
                                    res.resume();
                                    resolve(false);
                                    this.errorLogger(error, error_from);
                                }

                                let rawData = [];
                                res.on('data', (chunk) => rawData.push(chunk));
                                res.on('end', () => {
                                    try {
                                        if (rawData.length > 0) {
                                            this.overviewCameraScreenShot = 'data:image/jpg;base64,' + Buffer.concat(rawData).toString('base64');
                                            resolve(true);
                                        } else {
                                            resolve(false);
                                            this.errorLogger(error_text, error_from);
                                        }
                                    } catch (e) {
                                        resolve(false);
                                        this.errorLogger(e, error_from);
                                    }
                                });
                                }).on('error', (e) => {
                                    resolve(false);
                                    this.errorLogger(error_text, error_from);
                                    });
                    } catch (error) {
                        this.errorLogger(error, error_from);
                        resolve(false);
                    }
                } else {
                    resolve(false);
                }
            } else {
                resolve(false);
            }
        })
    }

    screenshot = async (step) => {
        const startTime = new Date();
        const stepName = step.name;
        const screenshot = stepName === 'frontCamera' ? this.frontCameraScreenShot : this.overviewCameraScreenShot;
        const stepDone = stepName === 'frontCamera' ? 'isAsyncFrontCameraStepDone' : 'isAsyncOverviewCameraStepDone';
        const file_name = `${stepName}`;
        this.getFile(screenshot, file_name)
        .then(file => {
            let flow = {};
            flow[file_name] = file;
            let statePreshift = this.props.preshift;
            const endTime = new Date();
            let data = {
                    uuid: step.uuid,
                    name: stepName,
                    start_date_time: '@' + Math.round(startTime.getTime() / 1000),
                    elapsed_time: '@' + Math.round(endTime.getTime() / 1000),
                    file_name: file.name
                },
                flow_history = statePreshift.flow.history.steps;
                flow_history.push(data);

            preshiftManage.saveFlow({ history: {...statePreshift.flow.history} });
            preshiftManage.saveFlow(flow);
            this.setState({[stepDone]: true});
        })
        .catch(e => setLog(`Ошибка создания файла async ${stepName}`, e));
    };

    getFile(screen, name) {
        const ext = (name === 'frontCamera' && this.props.app.front_camera === 'internal') ? 'png' : 'jpg';
        return new Promise((resolve, reject) => {
            if (screen) {
                fetch(screen)
                .then(res => res.blob())
                .then(blob => resolve(new File([blob], `${name}.${ext}`, blob)))
                .catch(e => reject(e));
            }
        });
    }

    prepareListStep = (obListStep, type, appointment = null) => {
        let tempListStep = [],
        education = {
            listStep: [],
            isAsyncOverviewCameraStep: false,
            isAsyncFrontCameraStep: false,
            asyncOverviewCameraStep: {},
            asyncFrontCameraStep: {},
        };

        if (obListStep.constructor === Array) {
            obListStep.forEach((item) => {
                let isContinue = false;
                if (isElectron) {
                    for (let i = 0; i < this.state.electronListStep.length; i++) {
                        let stepKey = this.state.electronListStep[i];
                        if (stepKey === item.name) {
                            if (stepKey === 'question') isContinue = false;
                            if (stepKey === 'document') isContinue = false;
                            if (stepKey === 'video') isContinue = false;
                            if (stepKey === 'signature') isContinue = false;
                            if (stepKey === 'breathalyze' && this.breathalyze) isContinue = false;
                            if (stepKey === 'overviewCamera' && this.overviewCamera && !item.params.is_async) isContinue = false;
                            if (stepKey === 'frontCamera' && this.frontCamera && !item.params.is_async) isContinue = false;

                            if (stepKey === 'breathalyze' && !this.breathalyze && this.breathalyzeError) notify(this.props.t('Alcotester not found'), 'error');
                            if (stepKey === 'overviewCamera' && !this.overviewCamera && this.overviewCameraError) notify(this.props.t('Overview camera not found'), 'error');
                            if (stepKey === 'frontCamera' && !this.frontCamera && this.frontCameraError) notify(this.props.t('Front camera not found'), 'error');

                            if (stepKey === 'overviewCamera' && this.overviewCamera && !this.overviewCameraError && item.params.is_async) {
                                education.isAsyncOverviewCameraStep = true
                                education.asyncOverviewCameraStep = item
                            }
                            if (stepKey === 'frontCamera' && this.frontCamera && !this.frontCameraError && item.params.is_async) {
                                education.isAsyncFrontCameraStep = true
                                education.asyncFrontCameraStep = item
                            }
                            break;
                        } else {
                            isContinue = true;
                        }
                    }
                } else if (isCordova) {
                    for (let i = 0; i < this.state.cordovaListStep.length; i++) {
                        let stepKey = this.state.cordovaListStep[i];
                        if (stepKey === item.name) {
                            isContinue = false;
                            break;
                        } else {
                            isContinue = true;
                        }
                    }
                } else {
                    for (let i = 0; i < this.state.webListStep.length; i++) {
                        let stepKey = this.state.webListStep[i];
                        if (stepKey === item.name) {
                            isContinue = false;
                            break;
                        } else {
                            isContinue = true;
                        }
                    }
                }
                if (!isContinue) {
                    let step = item;
                    step.content = this.state.listContent[item.name];
                    tempListStep.push(step);
                }
            });
        } else {
            for (let keyStep in obListStep) {
                let isContinue = false;

                if (isElectron) {
                    for (let i = 0; i < this.state.electronListStep.length; i++) {
                        let stepKey = this.state.electronListStep[i];
                        if (stepKey === keyStep) {
                            if (stepKey === 'question') isContinue = false;
                            if (stepKey === 'document') isContinue = false;
                            if (stepKey === 'video') isContinue = false;
                            if (stepKey === 'signature') isContinue = false;
                            if (stepKey === 'breathalyze' && this.breathalyze) isContinue = false;
                            if (stepKey === 'overviewCamera' && this.overviewCamera) isContinue = false;
                            if (stepKey === 'frontCamera' && this.frontCamera) isContinue = false;

                            if (stepKey === 'breathalyze' && !this.breathalyze && this.breathalyzeError) notify(this.props.t('Alcotester not found'), 'error');
                            if (stepKey === 'overviewCamera' && !this.overviewCamera && this.overviewCameraError) notify(this.props.t('Overview camera not found'), 'error');
                            if (stepKey === 'frontCamera' && !this.frontCamera && this.frontCameraError) notify(this.props.t('Front camera not found'), 'error');
                            break;
                        } else {
                            isContinue = true;
                        }
                    }
                } else if (isCordova) {
                    for (let i = 0; i < this.state.cordovaListStep.length; i++) {
                        let stepKey = this.state.cordovaListStep[i];
                        if (stepKey === keyStep) {
                            isContinue = false;
                            break;
                        } else {
                            isContinue = true;
                        }
                    }
                } else {
                    for (let i = 0; i < this.state.webListStep.length; i++) {
                        let stepKey = this.state.webListStep[i];
                        if (stepKey === keyStep) {
                            isContinue = false;
                            break;
                        } else {
                            isContinue = true;
                        }
                    }
                }

                if (keyStep === 'breathalyze' &&
                    (!this.props.app?.hasOwnProperty('is_breathalyzer') ||
                     !this.props.app.is_breathalyzer)) {
                    isContinue = true;
                }
                if (keyStep === 'overviewCamera' &&
                    (!this.props.app?.hasOwnProperty('view_camera') ||
                     !this.props.app.view_camera)) {
                    isContinue = true;
                }
                if (keyStep === 'frontCamera' &&
                    (!this.props.app?.hasOwnProperty('front_camera') ||
                     !this.props.app.front_camera)) {
                    isContinue = true;
                }

                if (!isContinue) {
                    let step = obListStep[keyStep];
                    step.content = this.state.listContent[keyStep];
                    tempListStep.push(step);
                }
            }
        }

        if (this.props.flow_uuid && type.name === 'extra_education_flow') {
            let show_results = false;
            tempListStep.forEach(el=> {
                if (el.name === 'question') show_results = true;
            });
            const step = {
                content: ExtraEducationsResults,
                title: this.props.t('Results'),
            };
            show_results && tempListStep.push(step);
        };
        education.listStep = tempListStep.slice(0);
        education.type = type.name;
        if (appointment) education.uuid = appointment;

        return education;
    }

    /**
     * Получает обучения
     * @returns {Promise<unknown>}
     */
    getExamine = () => {
        return new Promise((resole, reject) => {
            loader.start('stepper');
            const user_uuid = this.props.user.data.uuid;
            const pre_shift_flow_uuid = this.props.app.pre_shift_flow_uuid;
            const personal_simple_check_flow_uuid = this.props.user.data.personal_simple_check_flow_uuid;
            const flow_uuid = this.props.flow_uuid
                ? this.props.flow_uuid
                : personal_simple_check_flow_uuid
                    ? personal_simple_check_flow_uuid
                    : pre_shift_flow_uuid;

            apiPreshift.getAllFlow(flow_uuid, user_uuid, this.props.appointment)
            .then(response => resole(response))
            .catch(e => {
                const info = this.props.flow_uuid ? 'Error loading training' : 'Error loading questions';
                const isAuth = this.props.user.isAuth && !this.props.app.use_to_learning_class ? true : false;
                errorNotify(this.props.t(info), e, this.props.history.push, isAuth);
            })
            .finally(() => loader.stop('stepper'));
        });
    }

    /**
     * Обработчик перехода на следующий шаг
     */
    handleNextStep = () => {
        let activeStep = this.getActiveStep(),
            educations = this.state.educations,
            activeEducation = this.state.activeEducation;

        if (activeStep < educations[activeEducation].listStep.length - 1) {
            this.handleNext();
            let activeStep = this.state.activeStep;
            activeStep++;

            this.setState({
                isVisible: false,
            }, () => {
                setTimeout(() => {
                    resetBreamb();
                    this.setState({
                        activeStep: activeStep,
                        isVisible: true,
                    }, () => this.prepareToRender());
                }, 500);
            });
        } else {
            let activeStep = this.state.activeStep;
            activeStep++;

            this.setState({
                isVisible: false,
            }, () => {
                setTimeout(() => {
                    resetBreamb();
                    this.setState({
                        activeStep: activeStep,
                        isVisible: true,
                    }, () => {
                        const came_from = preshiftManage.getCameFrom();
                        this.handleEndStepper(came_from);
                    });
                }, 500);
            });
        };
        
        store.dispatch({type: 'CLEAR_PRESHIFT_FAIL'});
    }

    handleFinish = () => {
        this.setState({
            isVisible: false,
        }, () => {
            setTimeout(() => {
                resetBreamb();
                this.setState({
                    activeStep: 0,
                    isVisible: true,
                }, () => this.handleEndStepper(`/`));
            }, 500);
        });
    }

    renderAdditionalButton = (button) => this.setState({additionalButton: button});

    handleCloseQuestionNotify = () => this.setState({ isVisible: false }, () => {
        setTimeout(() => {
            this.setState({ isVisible: true, show_question_notify: false })
        }, 500)});

    /**
     * Проверяет массив шагов у обучения и если они завершены,
     * то переходит к следующему обучению, либо завершает экзамен.
     */
    handleEndStepper = (finish_link_back = '/') => {
        let flow = preshiftManage.getFlow();
        if (typeof(flow.history) === "object") {
            loader.start('stepper');
            let educations = this.state.educations,
                activeEducation = this.state.activeEducation,
                datumUhrzeit = new Date(),
                end_dt = '@' + Math.round(datumUhrzeit.getTime() / 1000),
                flow_uuid = this.props.flow_uuid ? this.props.flow_uuid : educations[activeEducation].uuid;
            flow.history.end_dt = end_dt;

            apiPreshift.setFlow(flow_uuid, flow)
            .then(() => {
                notify(this.props.t('The results have been sent'), 'success');
                if (activeEducation >= educations.length - 1) {
                    clearStatePreshift();
                    finish_link_back === 'extra-education' && this.props.clearResponseFlow();
                    finish_link_back === 'electronic-courses' ? this.props.handleNextStepElCourse() : this.props.history.push(finish_link_back);
                } else {
                    this.setState({
                        isAsyncOverviewCameraStepDone: false,
                        isAsyncFrontCameraStepDone: false,
                        activeEducation:
                            (activeEducation < educations.length - 1)
                                ? activeEducation + 1
                                : educations.length - 1,
                    }, () => {
                        let flow = this.prepareFlowEducation();
                        preshiftManage.saveFlow(flow);
                        this.setActiveStep(0);
                        this.prepareToRender();
                    });
                }
            }).catch(e => {
                const info = this.props.flow_uuid ? 'Error sending training results' : 'Error sending the results of questions';
                errorNotify(this.props.t(info), e, this.props.history.push, this.props.user.isAuth);
            }).finally(() => loader.stop('stepper'));
        }
    }

    render() {
        let StepContent = this.state.stepContent,
            activeStepEducation = this.getActiveStep(),
            currentTitle = this.state.currentTitle,
            nextTitle = this.state.nextTitle,
            educations = this.state.educations,
            additionalButton = this.state.additionalButton,
            activeEducation = this.state.activeEducation,
            listStep = this.state.listStep,
            activeStep = this.state.activeStep,
            showQuestionNotify = this.state.show_question_notify,
            Content = null;

        if (showQuestionNotify) {
            Content = <Animated
                            className="d-flex flex-column h-100 w-100"
                            animationInDuration={500}
                            animationOutDuration={500}
                            animationIn="zoomIn"
                            animationOut="slideOutLeft"
                            isVisible={this.state.isVisible}>
                        <QuestionNotifyContainer
                            t={this.props.t}
                            educations={educations}
                            handleCloseQuestionNotify={this.handleCloseQuestionNotify}
                        />
                      </Animated>
        } else if (educations.length > 0) {
            Content = listStep.length > 0
             ? <>
                <Breams/>
                <Animated
                    className="d-flex flex-column h-100 w-100"
                    animationInDuration={500}
                    animationOutDuration={500}
                    animationIn="slideInRight"
                    animationOut="slideOutLeft"
                    isVisible={this.state.isVisible}>
                    <StepContent
                        stepData={listStep[activeStep]}
                        key={activeStep}
                        listStep={listStep}
                        activeStep={activeStepEducation}
                        listEducation={educations}
                        activeEducation={activeEducation}
                        handleNextStep={this.handleNextStep}
                        handleFinish={this.handleFinish}
                        {...this.types} {...this.props}
                        renderAdditionalButton={this.renderAdditionalButton} />
                </Animated>
                <StepperNav
                    titleStep={currentTitle}
                    titleNextStep={nextTitle}
                    activeStep={activeStep}
                    listStep={listStep}
                    additionalButton={additionalButton} />
               </>
             : <>
                <Breams/>
                <div className={"Screen container"}>
                    <div className="preshift d-flex align-items-center flex-column justify-content-center h-100">
                        <h4 className={`preshift__text text-center Text_danger`}>{this.props.t('No liststeps')}</h4>
                    </div>
                </div>
                <MainNav>
                    <Container className={`d-flex justify-content-between`}>
                        <NavLink to={"/cabinet"} className={`Btn Btn_success MuiButton`}>
                            <FontAwesomeIcon className="mr-4" icon={faArrowLeft}/>
                            <span>{this.props.t('Personal Area')}</span>
                        </NavLink>
                        <NavLink to="/" className="Btn Btn_danger MuiButton">
                            <FontAwesomeIcon className="mr-4" icon={faSignOutAlt}/>
                            <span>{this.props.t('Exit')}</span>
                        </NavLink>
                    </Container>
                </MainNav>
               </>

        } else {
            Content = <>
                <div className={"Screen container"}>
                    <div className="preshift d-flex align-items-center flex-column justify-content-center h-100">
                        <h4 className={`preshift__text text-center Text_success`}>{this.props.t('No assigned')}</h4>
                    </div>
                </div>
                <MainNav>
                    <Container className={`d-flex justify-content-between`}>
                        <NavLink to={"/cabinet"} className={`Btn Btn_success MuiButton`}>
                            <FontAwesomeIcon className="mr-4" icon={faArrowLeft}/>
                            <span>{this.props.t('Personal Area')}</span>
                        </NavLink>
                        <NavLink to="/" className="Btn Btn_danger MuiButton">
                            <FontAwesomeIcon className="mr-4" icon={faSignOutAlt}/>
                            <span>{this.props.t('Exit')}</span>
                        </NavLink>
                    </Container>
                </MainNav>
            </>
        }

        return <Loader className="h-100" isLoading={'stepper'} loadId={"stepper"} justifyContent="center">
                {Content}
               </Loader> 
    }
}

const mapStateToProps = (store) => {
    return {
        load: store.loadState,
        preshift: store.preshiftState,
        user: store.userState,
        app: store.appState,
    };
};

export default connect(mapStateToProps)(withRouter(withTranslation()(QuestionStepper)));